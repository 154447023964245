<script setup>
  import { router } from '@inertiajs/vue3'
  import { Loader2Icon } from 'lucide-vue-next'
  import { Primitive } from 'reka-ui'
  import { ref } from 'vue'

  import { cn } from '@/lib/utils'

  import { buttonVariants } from '.'

  const props = defineProps({
    variant: { type: null, required: false },
    size: { type: null, required: false },
    class: { type: null, required: false },
    asChild: { type: Boolean, required: false },
    as: { type: null, required: false, default: 'button' },
    // oc props
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  })

  const navigating = ref(false)

  // disable button when navigating between pages
  router.on('before', (event) => {
    if (event?.detail?.visit?.url) {
      const pattern = /listings\/\d+\/edit(#.*)?$/

      // ignore polling on edit listing page
      if (!pattern.test(event.detail.visit.url)) {
        navigating.value = true
      }
    }
  })

  router.on('error', () => {
    navigating.value = false
  })

  router.on('success', () => {
    navigating.value = false
  })
</script>

<template>
  <Primitive
    :as="as"
    :as-child="asChild"
    :class="cn(buttonVariants({ variant, size }), props.class)"
    :disabled="disabled || loading || navigating"
  >
    <slot />
    <div
      v-if="loading"
      class="absolute left-0 top-0 flex h-full w-full items-center justify-center"
    >
      <Loader2Icon class="h-5 w-5 animate-spin text-white" />
    </div>
  </Primitive>
</template>
