export function isFile(value) {
  return value instanceof File
}

export function isHex(value) {
  return /^#[a-fA-F0-9]{6}$/.test(value)
}

export function isHsl(value) {
  return (
    /^(\d{1,3})\s(\d{1,3})%\s(\d{1,3})%$/.test(value) &&
    value
      .match(/^(\d{1,3})\s(\d{1,3})%\s(\d{1,3})%$/)
      .slice(1)
      .every((num, index) => {
        num = parseInt(num, 10)
        return index === 0
          ? num >= 0 && num <= 360 // Hue: 0 - 360
          : num >= 0 && num <= 100 // Saturation & Lightness: 0 - 100
      })
  )
}
