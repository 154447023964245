import { usePage } from '@inertiajs/vue3'

export function useActivePage() {
  const page = usePage()

  const isActivePage = (href, exact = false) => {
    if (href === window.location.origin) {
      return page.url === '/'
    }

    const url = new URL(href, window.location.origin)

    if (exact) {
      return page.url === url.pathname
    }

    return page.url.startsWith(url.pathname)
  }

  return {
    isActivePage,
  }
}
