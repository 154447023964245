<script setup>
  import { usePage } from '@inertiajs/vue3'
  import { isVNode, onMounted, watch } from 'vue'

  import {
    Toast,
    ToastClose,
    ToastDescription,
    ToastProvider,
    ToastTitle,
    ToastViewport,
  } from '@/Components/ui/toast'
  import { useToast } from '@/Components/ui/toast/use-toast'
  import { cn } from '@/lib/utils'

  const page = usePage()
  const { toast: showToast, toasts } = useToast()

  onMounted(() => {
    if (page.props.notification) {
      showToast({
        description: page.props.notification.body,
        duration: 4000,
        icon: page.props.notification.icon,
        iconClass: page.props.notification.iconClass,
        variant: page.props.notification.type,
      })
    }
  })

  watch(
    () => page.props.notification,
    (value) => {
      if (value) {
        showToast({
          description: value.body,
          duration: 4000,
          icon: value.icon,
          iconClass: value.iconClass,
          variant: value.type,
        })
      }
    },
  )
</script>

<template>
  <ToastProvider>
    <Toast v-for="toast in toasts" :key="toast.id" v-bind="toast">
      <div class="flex items-center gap-6">
        <component
          :is="toast.icon"
          v-if="toast.icon"
          :class="cn('size-5 shrink-0', toast.iconClass)"
        />
        <div class="grid gap-0.5">
          <ToastTitle v-if="toast.title" class="font-medium">
            {{ toast.title }}
          </ToastTitle>
          <template v-if="toast.description">
            <ToastDescription v-if="isVNode(toast.description)" class="opacity-100">
              <component :is="toast.description" />
            </ToastDescription>
            <ToastDescription v-else class="opacity-100">
              {{ toast.description }}
            </ToastDescription>
          </template>
        </div>
      </div>
      <component :is="toast.action" />
      <ToastClose class="text-background/50 hover:text-background" />
    </Toast>
    <ToastViewport />
  </ToastProvider>
</template>
