import { format, formatDistanceStrict, formatDistanceToNowStrict, parseJSON } from 'date-fns'
import { isString } from 'lodash'

export function formatCurrency(value) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(value)
}

export function formatDate(date, pattern = 'PP') {
  if (!date) return 'N/A'

  return format(parseJSON(date), pattern)
}

export function formatDateTime(date) {
  return formatDate(date, 'PP h:mmaaa')
}

export function formatDuration(start, end) {
  return formatDistanceStrict(parseJSON(end), parseJSON(start))
}

export function formatList(list) {
  if (list.length < 3) return list.join(' and ')

  return `${list.slice(0, -1).join(', ')}, and ${list[list.length - 1]}`
}

export function formatNumber(value, unit) {
  return value != null ? `${Number(value).toLocaleString()} ${unit ?? ''}`.trim() : '-'
}

export function formatOptions(options) {
  return options.map((option) => {
    if (isString(option)) return { label: option, value: option }

    return option // assumes option is an object with 'label' and 'value' properties
  })
}

export function formatResults(value) {
  if (isString(value)) return value

  return value.join('<br />')
}

export function formatTimeAgo(date) {
  if (!date) return 'N/A'

  return formatDistanceToNowStrict(parseJSON(date), { addSuffix: true, roundingMethod: 'ceil' })
}

export function formatWords(words, capitalization) {
  if (!['lowercase', 'uppercase'].includes(capitalization)) return words

  return words
    .split(' ')
    .map((word) => (capitalization === 'lowercase' ? word.toLowerCase() : word.toUpperCase()))
    .join(' ')
}
