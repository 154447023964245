<template>
  <Button
    data-sidebar="trigger"
    variant="sidebar"
    size="icon"
    :class="cn(props.class)"
    @click="toggleSidebar"
  >
    <slot />
  </Button>
</template>

<script setup>
  import { Button } from '@/Components/ui/button'
  import { useSidebar } from '@/Components/ui/sidebar/utils'
  import { cn } from '@/lib/utils'

  const props = defineProps({
    class: { type: null, required: false },
  })

  const { toggleSidebar } = useSidebar()
</script>
