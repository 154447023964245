import { router, usePage } from '@inertiajs/vue3'
import { createGlobalState, useScroll } from '@vueuse/core'
import { RefreshCwIcon } from 'lucide-vue-next'
import { computed, h, onMounted, ref, watch } from 'vue'

import { useToast } from '@/Components/ui/toast'
import ToastAction from '@/Components/ui/toast/ToastAction.vue'

const useSidebarLayout = createGlobalState(() => {
  // scroll
  const scrollArea = ref()

  const page = usePage()
  const { isScrolling, y } = useScroll(scrollArea, { behavior: 'smooth' })

  const isScrollingTo = ref(false)

  const scrollAreaStyle = computed(() => {
    let scrollAreaRemaining = 0

    if (page.props.impersonating?.user) scrollAreaRemaining += 40

    /*
     * values for calculation based on...
     * - topbar (h-16 = 4rem = 64px)
     * - impersonation_bar (h-10 = 2.5rem = 40px)
     */
    return {
      height: `calc(100dvh - var(--topbar-height) - ${scrollAreaRemaining}px)`,
    }
  })

  const resetScroll = () => (y.value = 0)

  const scrollTo = (yPosition) => {
    y.value += yPosition
    isScrollingTo.value = true
  }

  onMounted(() => {
    scrollArea.value = document.getElementById('scroll_area')
  })

  router.on('navigate', (event) => {
    y.value = 0
  })

  // prevents issue with page loading and scroll area computing incorrectly
  window.addEventListener('scroll', () => {
    if (window.scrollY !== 0) {
      window.scrollTo(0, 0)
    }
  })

  watch(isScrolling, (value) => {
    if (isScrollingTo.value && !value) {
      isScrollingTo.value = false
    }
  })

  // light/dark mode
  // const isDark = useDark()
  // const toggleDark = useToggle(isDark)

  // listings
  const publishListingToast = ref()

  const { dismiss, toast } = useToast()

  const showPublishListingToast = () => {
    publishListingToast.value = toast({
      description:
        'This may take a couple of minutes. We will notify you once your listing has successfully been published.',
      duration: 60000,
      icon: RefreshCwIcon,
      iconClass: 'animate-spin-slow ease-in-out',
      title: 'Publishing your listing...',
    })
  }

  // receive all notifications on auth'd user's "broadcast" channel
  window.Echo.private(`users.${page.props.auth.user.id}`).notification((notification) => {
    if (publishListingToast.value) {
      dismiss(publishListingToast.value.id)

      toast({
        action: notification.data.action
          ? h(ToastAction, {
              altText: notification.data.action,
              class: 'hover:bg-background/10',
              innerHTML: notification.data.action,
              onClick: () => window.open(notification.data.url, '_blank'),
            })
          : undefined,
        description: notification.data.message,
        duration: 60000,
        title: notification.data.title,
      })
    }
  })

  return {
    // isDark,
    isScrollingTo,
    scrollAreaStyle,
    resetScroll,
    scrollTo,
    showPublishListingToast,
    // toggleDark,
  }
})

export default useSidebarLayout
