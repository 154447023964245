<template>
  <div :class="cn('flex w-full items-center', props.class)">
    <template v-if="!hideImage">
      <component :is="icon" v-if="icon" :class="cn('mr-2.5 size-6 shrink-0', iconClass ?? '')" />
      <Avatar
        v-else
        :alt="text"
        :class="cn('mr-2.5 shrink-0', props.avatarClass)"
        :fallback="fallback"
        :size="size"
        :shape="shape"
        :src="avatar"
      />
    </template>
    <div v-if="text || subtext" class="flex grow flex-col justify-center overflow-hidden text-left">
      <div class="inline-flex max-w-full items-center overflow-ellipsis">
        <component
          :is="componentType"
          :class="[
            cn('block truncate text-sm text-foreground', props.textClass),
            hasClickEventListener || href ? 'cursor-pointer font-medium' : '',
          ]"
          :href="href ?? undefined"
          :tabindex="-1"
          type="button"
          @click="$emit('click', $event)"
        >
          {{ text }}
        </component>
        <Tooltip v-if="tooltip" :icon="tooltipIcon" :icon-class="['ml-1', tooltipIconClass]">
          {{ tooltip }}
        </Tooltip>
      </div>
      <div v-if="subtext" :class="cn('truncate text-xs text-muted-foreground', props.subtextClass)">
        {{ subtext }}
      </div>
      <div v-if="tertiary" :class="cn('truncate text-xs', props.tertiaryClass)">
        {{ tertiary }}
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, getCurrentInstance } from 'vue'

  import Avatar from '@/Components/oc/avatar/Avatar.vue'
  import Tooltip from '@/Components/oc/Tooltip.vue'
  import { cn } from '@/lib/utils'

  defineEmits(['click'])

  const props = defineProps({
    avatar: {
      default: undefined,
      type: String,
    },
    avatarClass: {
      default: '',
      type: String,
    },
    class: {
      required: undefined,
      type: null,
    },
    hideImage: {
      default: false,
      type: Boolean,
    },
    href: {
      default: '',
      type: String,
    },
    icon: {
      default: undefined,
      type: null,
    },
    iconClass: {
      required: undefined,
      type: null,
    },
    fallback: {
      default: undefined,
      type: null,
    },
    shape: {
      default: 'circle',
      type: String,
    },
    size: {
      default: 'sm',
      type: String,
    },
    subtext: {
      default: '',
      type: String,
    },
    subtextClass: {
      required: undefined,
      type: null,
    },
    tertiary: {
      default: '',
      type: String,
    },
    tertiaryClass: {
      required: undefined,
      type: null,
    },
    text: {
      default: '',
      type: String,
    },
    textClass: {
      required: undefined,
      type: null,
    },
    tooltip: {
      default: '',
      type: String,
    },
    tooltipIcon: {
      required: undefined,
      type: null,
    },
    tooltipIconClass: {
      default: undefined,
      type: null,
    },
    useAnchor: {
      default: false,
      type: Boolean,
    },
  })

  const componentType = computed(() => {
    if (hasClickEventListener.value) return 'button'
    if (props.href) return props.useAnchor ? 'a' : 'Link'

    return 'div'
  })

  const hasClickEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onClick)
</script>
