import { usePage } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'

import { hexToRgb } from './colors'
import { LISTING_STATUS_ENUM } from './enums'
import { formatNumber } from './format'

export function getInitials(str) {
  if (!str) return ''

  const words = str
    .split(/[\s-]+/)
    .filter((word) => !['and', 'the', 'to'].includes(word.toLowerCase()))

  return words
    .map((word) => word.charAt(0).toUpperCase())
    .join('')
    .slice(0, 3)
}

export function getLastWord(text) {
  const lastWord = text.trim().split(' ').pop()

  return lastWord.charAt(0).toLowerCase() + lastWord.slice(1)
}

export function getListingDaysOnMarket(listing, status, showAbbreviation = true) {
  if (status !== LISTING_STATUS_ENUM.inactive) {
    if (listing.days_on_market == null) return

    return listing.days_on_market >= 0
      ? `${formatNumber(listing.days_on_market)} Day${listing.days_on_market === 1 ? '' : 's'}${showAbbreviation ? ' on Market' : ''}`
      : 'Coming Soon'
  }

  return 'Draft'
}

export function getListingStatus(listing) {
  if (!listing?.lookups?.length) return '-'

  return [
    listing.lookups.find((lookup) => lookup.type === 'property_sub_type')?.standard_label ||
      listing.lookups.find((lookup) => lookup.type === 'property_type')?.standard_label,
    listing.lookups.find((lookup) => lookup.type === 'mls_status')?.standard_label,
  ]
    .filter((e) => e?.length)
    .join(' · ')
}

export function getMlsTypeStyle(primaryColor) {
  if (!primaryColor) return

  return {
    background: `rgb(${hexToRgb(primaryColor)}, 0.2)`,
    'border-color': primaryColor,
    color: primaryColor,
  }
}

export function getVisible(items) {
  return items
    .map((item) => ({
      ...item,
      visible: typeof item.visible === 'function' ? item.visible() : item.visible !== false,
    }))
    .filter((item) => item.visible)
}

export function getUserRole(user) {
  const i18n = useI18n()
  const page = usePage()

  if (user.is_broker_admin) return i18n.t(`role.${page.props.enums.Role.BrokerAdmin}`)
  if (user.is_office_admin) return i18n.t(`role.${page.props.enums.Role.OfficeAdmin}`)
  if (user.is_office_member) return i18n.t(`role.${page.props.enums.Role.OfficeMember}`)
}
