import { getLuminance, hexToHsl } from '@/lib/colors'

export function useBranding() {
  const resetCssPrimaryColor = () => {
    const rootStyle = document.querySelector(':root').style

    rootStyle.setProperty('--primary', '148.42 47.26% 60.59%')
    rootStyle.setProperty('--primary-foreground', '0 0% 100%')
  }

  const setCssPrimaryColor = (primaryColor) => {
    if (!primaryColor) return

    const primaryForegroundColor = getLuminance(primaryColor) < 0.5 ? '0 0% 100%' : '240 10% 3.9%'

    const rootStyle = document.querySelector(':root').style

    rootStyle.setProperty('--primary', hexToHsl(primaryColor))
    rootStyle.setProperty('--primary-foreground', primaryForegroundColor)
  }

  return {
    resetCssPrimaryColor,
    setCssPrimaryColor,
  }
}
