<template>
  <TooltipProvider>
    <Tooltip :delay-duration="1">
      <TooltipTrigger as-child>
        <component
          :is="icon ?? CircleHelpIcon"
          :class="cn('size-4 text-muted-foreground', props.iconClass)"
        />
      </TooltipTrigger>
      <TooltipContent :class="cn('max-w-80 lg:max-w-xs', props.contentClass)">
        <slot />
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
</template>

<script setup>
  import { CircleHelpIcon } from 'lucide-vue-next'

  import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/Components/ui/tooltip'
  import { cn } from '@/lib/utils'

  const props = defineProps({
    contentClass: { type: null, required: false },
    icon: { type: null, default: undefined },
    iconClass: { type: null, required: false },
  })
</script>
