<template>
  <DropdownMenu v-model:open="open">
    <DropdownMenuTrigger class="size-9">
      <Button :class="{ 'border bg-background shadow-inner': open }" size="icon" variant="sidebar">
        <div class="relative">
          <BellIcon class="size-4" aria-hidden="true" />
          <div
            v-if="hasUnreadNotifications"
            class="absolute -right-1 -top-1 size-3 rounded-full border-2 border-sidebar bg-destructive transition-colors"
          />
        </div>
        <span class="sr-only">Open notifications menu</span>
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="start" :align-offset="-16" class="w-screen max-w-xs pb-0">
      <h3 class="px-4 pb-3 pt-2 text-sm font-medium text-muted-foreground"> Notifications </h3>
      <div class="-mx-1 max-h-[80dvh] divide-y overflow-y-auto border-t">
        <div
          v-for="notification in page.props.notifications.latest"
          :key="notification.id"
          :class="[
            'flex flex-row items-start space-x-4 px-4 py-3',
            { 'bg-muted': !notification.read_at },
          ]"
        >
          <Avatar
            :alt="notification.title"
            :class="{ 'bg-background': !notification.read_at }"
            :fallback="ImageIcon"
            :src="notification?.listing?.thumbnail_url"
          />
          <button
            class="group text-left text-muted-foreground"
            @click="markNotificationAsRead(notification)"
          >
            <p class="text-sm leading-5 text-gray-800 group-hover:underline">
              {{ notification.data?.message }}
            </p>
            <p v-if="notification.listing" class="mt-1 text-xs leading-4 text-gray-500">
              {{ notification.listing?.name }}
            </p>
            <p class="mt-1.5 text-xs text-gray-500">
              {{ formatTimeAgo(notification.created_at) }}
            </p>
          </button>
        </div>
      </div>
    </DropdownMenuContent>
  </DropdownMenu>
</template>

<script setup>
  import { router, usePage } from '@inertiajs/vue3'
  import { BellIcon, ImageIcon } from 'lucide-vue-next'
  import { computed, ref } from 'vue'

  import Avatar from '@/Components/oc/avatar/Avatar.vue'
  import Button from '@/Components/ui/button/Button.vue'
  import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
  } from '@/Components/ui/dropdown-menu'
  import { formatTimeAgo } from '@/lib/format'

  const page = usePage()

  const open = ref(false)

  // TODO (James): can use page.props.notifications.unreadCount > 0 once we show all notifications
  const hasUnreadNotifications = computed(() =>
    page.props.notifications.latest.some((notification) => !notification.read_at),
  )

  const markNotificationAsRead = (notification) => {
    router.post(route('notifications.mark-read', notification), null, {
      onSuccess: () => {
        if (notification?.data?.listing_id && notification?.data?.publish_request_uuid) {
          window.location.href = route('listings.requests.edit', {
            listing: notification.data.listing_id,
            publish_request: notification.data.publish_request_uuid,
          })
        } else if (notification?.listing?.id) {
          window.location.href = route('listings.edit', notification.listing)
        }
      },
    })
  }
</script>
