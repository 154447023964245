<template>
  <SidebarHeader class="h-topbar border-b">
    <SidebarMenu>
      <SidebarMenuItem class="flex h-11 items-center">
        <div
          :class="{ 'flex w-full justify-center': isCollapsed && !isMobile }"
          @mouseenter="show"
          @mouseleave="hide"
        >
          <AppSidebarTrigger v-if="showSidebarTrigger && !isMobile" class="size-8">
            <PanelLeftIcon class="size-4" aria-hidden="true" />
            <span class="sr-only">Toggle sidebar</span>
          </AppSidebarTrigger>
          <template v-else>
            <IconOcusell v-if="isCollapsed && !isMobile" class="size-7 text-foreground" />
            <a v-else href="/" :tabindex="-1">
              <img alt="Ocusell logo" class="ml-1.5 h-7 w-auto" src="/images/logo.png" />
            </a>
          </template>
        </div>

        <div
          v-if="isMobile || !isCollapsed"
          class="ml-auto mr-1.5 flex flex-nowrap overflow-hidden"
        >
          <AppSidebarNotifications v-if="!isSuper" />
          <AppSidebarTrigger class="shrink-0">
            <component :is="isMobile ? XIcon : PanelLeftIcon" class="size-4" aria-hidden="true" />
            <span class="sr-only">Toggle sidebar</span>
          </AppSidebarTrigger>
        </div>
      </SidebarMenuItem>
    </SidebarMenu>
  </SidebarHeader>
</template>

<script setup>
  import { PanelLeftIcon, XIcon } from 'lucide-vue-next'
  import { ref, watch } from 'vue'

  import IconOcusell from '@/Components/oc/icon/IconOcusell.vue'
  import { SidebarHeader, SidebarMenu, SidebarMenuItem } from '@/Components/ui/sidebar'
  import { useSidebar } from '@/Components/ui/sidebar/utils'
  import { useAuth } from '@/hooks/useAuth'

  import { AppSidebarNotifications, AppSidebarTrigger } from '.'

  const { isSuper } = useAuth()
  const { isCollapsed, isMobile, state } = useSidebar()

  const showSidebarTrigger = ref(false)

  const hide = () => {
    if (!isCollapsed.value) return

    showSidebarTrigger.value = false
  }

  const show = () => {
    if (!isCollapsed.value) return

    showSidebarTrigger.value = true
  }

  watch(state, (value) => {
    if (value !== 'collapsed') {
      showSidebarTrigger.value = false
    }
  })
</script>
