import { createGlobalState } from '@vueuse/core'
import { computed, nextTick, reactive } from 'vue'

import { hslToHex } from '@/lib/colors'

export const useConfetti = createGlobalState(() => {
  const colors = computed(() => {
    if (!style.value) return []

    return [
      hslToHex(style.value.getPropertyValue('--vis-color0').trim()),
      hslToHex(style.value.getPropertyValue('--vis-color1').trim()),
      hslToHex(style.value.getPropertyValue('--vis-color2').trim()),
      hslToHex(style.value.getPropertyValue('--vis-color3').trim()),
      hslToHex(style.value.getPropertyValue('--vis-color4').trim()),
      hslToHex(style.value.getPropertyValue('--vis-color5').trim()),
    ]
  })

  const style = computed(() => getComputedStyle(document.body))

  const confetti = reactive({
    colors: colors.value,
    particleCount: 200,
    stageHeight: document.documentElement.clientHeight || 0,
    stageWidth: document.documentElement.clientWidth || 0,
    visible: false,
  })

  const explode = async () => {
    confetti.visible = false
    await nextTick()
    confetti.visible = true
  }

  return {
    confetti,
    explode,
  }
})
