import { isObject } from 'lodash'

export const hasOneOf = (componentName, propertyName, values) => {
  return (value) => {
    if (value && !values.includes(value)) {
      console.error(
        `${componentName}: Bad value "${value}". The "${propertyName}" prop must be one of the following:`,
        values.join(', '),
      )

      return false
    }

    return true
  }
}

export const hasKeys = (componentName, propertyName, requiredKeys) => {
  return (value) => {
    if (value == null || !isObject(value)) {
      console.error(
        `${componentName}: Bad value "${JSON.stringify(value)}". The "${propertyName}" prop must be an object.`,
      )

      return false
    }

    const keys = Object.keys(value)

    if (!requiredKeys.every((key) => keys.includes(key))) {
      console.error(
        `${componentName}: Bad value "${JSON.stringify(value)}". The "${propertyName}" prop requires the following keys to be present:`,
        requiredKeys.join(', '),
      )

      return false
    }

    return true
  }
}

export const numRange = (componentName, propertyName, minVal, maxVal) => {
  return (value) => {
    if (value == null || value === '') {
      return true
    }

    if (!(value >= minVal && value <= maxVal)) {
      console.error(
        `${componentName}: Bad value "${value}". The "${propertyName}" prop must be between "${minVal}" and "${maxVal}".`,
      )

      return false
    }

    return true
  }
}
