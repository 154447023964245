import { cva } from 'class-variance-authority'

export { default as Button } from './Button.vue'

export const buttonVariants = cva(
  'relative inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors disabled:pointer-events-none disabled:opacity-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'bg-foreground text-background hover:bg-foreground/80',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-blue-500 font-medium',
        outline: 'border border-input bg-background text-accent-foreground hover:bg-accent',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        sidebar:
          'rounded-full text-sidebar-foreground hover:border hover:bg-background hover:text-sidebar-accent-foreground active:border active:bg-background active:text-sidebar-accent-foreground active:shadow-inner',
        stepper: 'bg-green-500 text-white',
        topbar:
          'rounded-full border border-transparent hover:border-input active:bg-secondary active:shadow-inner',
      },
      size: {
        default: 'h-10 px-4 py-2',
        xs: 'text-xs h-7 rounded px-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'size-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)
