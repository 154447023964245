<script setup>
  import { AlertDialogTitle } from 'reka-ui'
  import { computed } from 'vue'

  import { cn } from '@/lib/utils'

  const props = defineProps({
    asChild: { type: Boolean, required: false },
    as: { type: null, required: false },
    class: { type: null, required: false },
  })

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })
</script>

<template>
  <AlertDialogTitle v-bind="delegatedProps" :class="cn('text-lg font-medium', props.class)">
    <slot />
  </AlertDialogTitle>
</template>
