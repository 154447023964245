<template>
  <SidebarProvider v-model:open="open" :default-open="true">
    <AppSidebar />
    <SidebarInset
      class="w-[calc(100%-[--sidebar-width])] overflow-x-auto overflow-y-hidden bg-background"
    >
      <TopBar />
      <ImpersonationBar />

      <main id="scroll_area" class="overflow-y-auto" :style="scrollAreaStyle" scroll-region>
        <slot />
      </main>
    </SidebarInset>
  </SidebarProvider>

  <Confetti />
  <ConfirmDialog />
  <Toaster />
</template>

<script setup>
  import { usePage } from '@inertiajs/vue3'
  import { computed, onBeforeMount, ref, watch } from 'vue'

  import Confetti from '@/Components/oc/Confetti.vue'
  import ConfirmDialog from '@/Components/oc/ConfirmDialog.vue'
  import Toaster from '@/Components/oc/Toaster.vue'
  import { SidebarInset, SidebarProvider } from '@/Components/ui/sidebar'
  import { useBranding } from '@/hooks/useBranding'
  import useSidebarLayout from '@/hooks/useSidebarLayout'
  import { useUserflow } from '@/hooks/useUserflow'

  import { ImpersonationBar, TopBar } from './partials'
  import AppSidebar from './partials/app-sidebar/AppSidebar.vue'

  const { setCssPrimaryColor } = useBranding()
  const page = usePage()
  const { scrollAreaStyle } = useSidebarLayout()
  const { initUserflow, updateUser } = useUserflow()

  const open = ref(true)

  const onBoardingTasks = computed(() => ({
    has_mls_credentials: page.props.hasMlsCredentials,
    name: page.props.auth.user.fullname,
    phone: page.props.auth.user.phone,
    profile_photo: page.props.auth.user.profile_photo,
  }))

  onBeforeMount(() => {
    initUserflow()
    setCssPrimaryColor(page.props.branding.accent_color)
  })

  watch(
    onBoardingTasks,
    (value) => {
      if (value) {
        updateUser({ ...value })
      }
    },
    { immediate: false },
  )
</script>

<style>
  html,
  body,
  #app {
    @apply !mb-0 h-full overflow-hidden;
  }
</style>
