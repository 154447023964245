<template>
  <Avatar :key="componentKey" :class="props.class" :shape="shape" :size="size" :style="props.style">
    <AvatarImage v-if="src" :alt="alt" :src="src" />
    <AvatarFallback v-else>
      <template v-if="fallback">
        <span v-if="typeof fallback === 'string'">{{ fallback }}</span>
        <component :is="fallback" v-else :class="fallbackIconSize" />
      </template>
      <span v-else>{{ getInitials(alt) }}</span>
    </AvatarFallback>
  </Avatar>
</template>

<script setup>
  import { computed, ref, watch } from 'vue'

  import { Avatar, AvatarFallback, AvatarImage } from '@/Components/ui/avatar'
  import { getInitials } from '@/lib/getters'
  import { hasOneOf } from '@/lib/validators'

  const props = defineProps({
    alt: {
      default: '',
      type: String,
    },
    class: {
      required: false,
      type: null,
    },
    fallback: {
      default: undefined,
      type: null,
    },
    shape: {
      default: 'circle',
      type: String,
      validator: hasOneOf('Avatar', 'shape', ['circle', 'square']),
    },
    size: {
      default: 'sm',
      type: String,
      validator: hasOneOf('Avatar', 'size', ['xxs', 'xs', 'sm', 'base', 'lg', 'xl']),
    },
    src: {
      default: undefined,
      type: String,
    },
    style: {
      required: false,
      type: null,
    },
  })

  const componentKey = ref(0)

  const fallbackIconSize = computed(() => {
    switch (props.size) {
      case 'xxs':
        return 'size-2'
      case 'xs':
        return 'size-3.5'
      case 'base':
        return 'size-5'
      case 'lg':
        return 'size-7'
      case 'xl':
        return 'size-12'
      default:
        return 'size-4'
    }
  })

  watch(
    () => props.src,
    () => {
      componentKey.value += 1
    },
    { immediate: true },
  )
</script>
