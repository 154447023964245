<template>
  <div
    v-if="user"
    class="flex h-banner w-full justify-center overflow-hidden bg-amber-50 px-6 text-sm font-medium text-amber-500 md:px-8"
  >
    <div class="flex items-center gap-2">
      <p class="line-clamp-1"> You're currently impersonating: {{ user.fullname }} </p>
      <a
        :href="route('impersonate.end')"
        class="text-nowrap rounded-full bg-destructive px-2.5 py-0.5 text-destructive-foreground"
      >
        Stop Impersonating
      </a>
    </div>
  </div>
</template>

<script setup>
  import { usePage } from '@inertiajs/vue3'
  import { computed } from 'vue'

  const page = usePage()

  const user = computed(() => {
    return page.props.impersonating?.user
  })
</script>
