import { isHex, isHsl } from './checkers'

export function getLuminance(hex) {
  if (!hex || !isHex(hex)) {
    console.error(
      `getLuminance: Bad value "${JSON.stringify(hex)}". Pass a valid hex value (i.e. #FFFFFF)`,
    )

    return 0
  }

  hex = hex.replace(/^#/, '')

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return 0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255)
}

export function hexToHsl(hex, luminosity = 0) {
  if (!hex || !isHex(hex)) {
    console.error(
      `hexToHsl: Bad value "${JSON.stringify(hex)}". Pass a valid hex value (i.e. #FFFFFF)`,
    )

    return '0 0% 100%'
  }

  hex = hex.replace(/^#/, '')

  const r = parseInt(hex.slice(0, 2), 16) / 255
  const g = parseInt(hex.slice(2, 4), 16) / 255
  const b = parseInt(hex.slice(4, 6), 16) / 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)

  const l = (max + min) / 2

  let h, s

  if (max === min) {
    h = s = 0
  } else {
    const delta = max - min

    s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min)

    switch (max) {
      case r:
        h = (g - b) / delta + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / delta + 2
        break
      case b:
        h = (r - g) / delta + 4
        break
    }

    h *= 60
  }

  const adjustedL = Math.max(0, Math.min(l + luminosity / 100, 1))

  const hRounded = Math.round(h)
  const sRounded = Math.round(s * 100)
  const lRounded = Math.round(adjustedL * 100)

  return `${hRounded} ${sRounded}% ${lRounded}%`
}

export function hexToRgb(hex) {
  if (!hex || !isHex(hex)) {
    console.error(
      `hexToRgb: Bad value "${JSON.stringify(hex)}". Pass a valid hex value (i.e. #FFFFFF)`,
    )

    return [255, 255, 255]
  }

  return hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16))
}

export function hslToHex(hsl) {
  if (!isHsl(hsl)) {
    console.error(
      `hslToHex: Bad value "${JSON.stringify(hsl)}". Pass a valid hsl value (i.e. 0 0% 100%)`,
    )

    return '#ffffff'
  }

  const [h, s, l] = hsl.replaceAll('%', '').split(' ')

  const hDecimal = l / 100
  const a = (s * Math.min(hDecimal, 1 - hDecimal)) / 100

  const f = (n) => {
    const k = (n + h / 30) % 12
    const color = hDecimal - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)

    // convert to Hex and prefix with "0" if required
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0')
  }

  return `#${f(0)}${f(8)}${f(4)}`
}
