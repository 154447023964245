<template>
  <div
    v-if="confetti.visible"
    class="pointer-events-none absolute left-0 top-0 flex h-full min-h-full w-full items-start justify-center"
  >
    <ConfettiExplosion
      :colors="confetti.colors"
      :particle-count="confetti.particleCount"
      :stage-height="confetti.stageHeight"
      :stage-width="confetti.stageWidth"
    />
  </div>
</template>

<script setup>
  import { useConfetti } from '@/hooks/useConfetti'

  const { confetti } = useConfetti()
</script>
