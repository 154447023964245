export const LISTING_STATUS_ENUM = Object.freeze({
  active: 'active',
  archived: 'archived',
  inactive: 'inactive',
  out_of_sync: 'out_of_sync',
})

export const STATUS_ENUM = Object.freeze({
  Complete: 'Complete',
  Error: 'Error',
  Queued: 'Queued',
})
