<template>
  <div
    class="sticky top-0 z-40 flex h-topbar shrink-0 items-center gap-2 border-b bg-primary px-6 text-primary-foreground shadow-sm md:px-8"
  >
    <template v-if="isMobile">
      <AppSidebarTrigger
        class="-ml-2 shrink-0 border-primary-foreground !bg-transparent !text-primary-foreground"
      >
        <PanelLeftIcon class="size-4" aria-hidden="true" />
        <span class="sr-only">Toggle sidebar</span>
      </AppSidebarTrigger>
      <Separator class="h-4 bg-primary-foreground" orientation="vertical" />
    </template>

    <div id="topbar" class="overflow-hidden" />
    <div id="topbar_secondary" />
    <div id="topbar_action" class="ml-auto" />
  </div>
</template>

<script setup>
  import { PanelLeftIcon } from 'lucide-vue-next'

  import Separator from '@/Components/ui/separator/Separator.vue'
  import { useSidebar } from '@/Components/ui/sidebar/utils'

  import AppSidebarTrigger from './app-sidebar/AppSidebarTrigger.vue'

  const { isMobile } = useSidebar()
</script>
