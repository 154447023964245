export const en = {
  action: {
    cancel: 'Cancel',
    continue: 'Continue',
    next: 'Next',
    resolve: 'Resolve',
    submit: 'Submit',
  },
  password: {
    confirm: 'Confirm New Password',
    current: 'Enter Current Password',
    new: 'Enter New Password',
  },
  role: {
    broker_admin: 'Broker Admin',
    office_admin: 'Office Admin',
    office_member: 'Office Member',
    BROKER_ADMIN: 'Broker Admin',
    TEAM_ADMIN: 'Office Admin',
    TEAM_MEMBER: 'Office Member',
  },
  social_media: {
    facebook: 'Facebook',
    instagram: 'Instagram',
    linkedin: 'LinkedIn',
    twitter: 'Twitter',
  },
}
