import { usePage } from '@inertiajs/vue3'
import { noop } from 'lodash'
import userflow from 'userflow.js'

import { useAuth } from './useAuth'

export function useUserflow() {
  const userflowKey = import.meta.env.VITE_USERFLOW_KEY

  const { isImpersonating, isSuper } = useAuth()
  const page = usePage()

  const isEnabled = Boolean(userflowKey && (!isSuper.value || !isImpersonating))

  const whenEnabled =
    (fn) =>
    (...args) => {
      return isEnabled ? fn(...args) : noop()
    }

  const initUserflow = (attributes) => {
    userflow.init(userflowKey)
    userflow.setResourceCenterLauncherHidden(true)
    userflow.identify(page.props.auth.user.id, attributes)
  }

  const toggleResourceCenter = () => userflow.toggleResourceCenter()
  const updateUser = (attributes) => userflow.updateUser(attributes)

  return {
    isEnabled,
    initUserflow: whenEnabled(initUserflow),
    toggleResourceCenter: whenEnabled(toggleResourceCenter),
    updateUser: whenEnabled(updateUser),
  }
}
