<template>
  <SidebarGroup v-if="page.props.branding.logo_url">
    <img
      alt="Account logo"
      :class="[
        '-mb-2 mr-auto mt-2 w-auto object-contain mix-blend-multiply transition-all',
        state === 'collapsed' ? 'h-8' : 'h-20',
      ]"
      :src="page.props.branding.logo_url"
    />
  </SidebarGroup>
  <SidebarGroup>
    <SidebarMenu>
      <SidebarMenuItem v-for="link in visibleLinks" :key="link.name">
        <SidebarMenuButton :tooltip="link.name" as-child @click="setOpenMobile(false)">
          <AppSidebarLink :link="link" />
        </SidebarMenuButton>
      </SidebarMenuItem>
      <SidebarMenuItem v-if="!isImpersonating">
        <SidebarMenuButton tooltip="Helpdesk" as-child>
          <a class="cursor-pointer" @click="toggleResourceCenter">
            <InfoIcon />
            <span class="font-medium"> Helpdesk </span>
          </a>
        </SidebarMenuButton>
      </SidebarMenuItem>
    </SidebarMenu>
  </SidebarGroup>
</template>

<script setup>
  import { usePage } from '@inertiajs/vue3'
  import { HomeIcon, InfoIcon, Share2Icon, UsersIcon } from 'lucide-vue-next'
  import { computed } from 'vue'

  import {
    SidebarGroup,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    useSidebar,
  } from '@/Components/ui/sidebar'
  import { useAuth } from '@/hooks/useAuth'
  import { useUserflow } from '@/hooks/useUserflow'
  import { getVisible } from '@/lib/getters'

  import AppSidebarLink from './AppSidebarLink.vue'

  const { isImpersonating } = useAuth()
  const page = usePage()
  const { setOpenMobile, state } = useSidebar()
  const { toggleResourceCenter } = useUserflow()

  const links = [
    {
      href: route('listings.index'),
      icon: HomeIcon,
      name: 'Listings',
    },
    {
      href: route('users.index'),
      icon: UsersIcon,
      name: 'Users',
    },
    {
      href: route('social-media.index'),
      icon: Share2Icon,
      name: 'Social Media',
      visible: () => page.props.features.social_media,
    },
  ]

  const visibleLinks = computed(() => getVisible(links))
</script>
