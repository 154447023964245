<template>
  <svg
    :class="cn('text-[#89D5AD]', props.class)"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="M 4.074219 6.476562 C 3.835938 6.476562 3.601562 6.363281 3.460938 6.152344 C 3.230469 5.8125 3.320312 5.355469 3.660156 5.125 L 10.648438 0.421875 C 11.523438 -0.152344 12.640625 -0.140625 13.496094 0.449219 L 20.320312 5.128906 C 20.65625 5.359375 20.742188 5.820312 20.511719 6.15625 C 20.28125 6.492188 19.820312 6.578125 19.484375 6.347656 L 12.660156 1.667969 C 12.300781 1.417969 11.832031 1.414062 11.464844 1.652344 L 4.484375 6.351562 C 4.363281 6.433594 4.21875 6.476562 4.074219 6.476562"
      fill="currentColor"
    />
    <path
      d="M 12.246094 22.628906 Z M 12.03125 24 C 12.019531 24 12.007812 24 11.996094 24 C 11.558594 23.988281 11.160156 23.730469 10.9375 23.316406 L 8.585938 18.707031 L 5.785156 18.707031 C 4.378906 18.707031 3.234375 17.5625 3.234375 16.15625 L 3.234375 8.003906 C 3.234375 7.59375 3.5625 7.265625 3.972656 7.265625 C 4.378906 7.265625 4.710938 7.59375 4.710938 8.003906 L 4.710938 16.15625 C 4.710938 16.75 5.191406 17.230469 5.785156 17.230469 L 9.492188 17.230469 L 12.042969 22.234375 L 14.484375 17.230469 L 18.214844 17.230469 C 18.808594 17.230469 19.289062 16.75 19.289062 16.15625 L 19.289062 8.003906 C 19.289062 7.59375 19.621094 7.265625 20.027344 7.265625 C 20.4375 7.265625 20.765625 7.59375 20.765625 8.003906 L 20.765625 16.15625 C 20.765625 17.5625 19.621094 18.707031 18.214844 18.707031 L 15.410156 18.707031 L 13.140625 23.347656 C 12.886719 23.757812 12.472656 24 12.03125 24"
      fill="currentColor"
    />
    <path
      d="M 17.152344 11.496094 C 17.152344 12.125 16.640625 12.632812 16.011719 12.632812 C 15.382812 12.632812 14.871094 12.125 14.871094 11.496094 C 14.871094 10.863281 15.382812 10.355469 16.011719 10.355469 C 16.640625 10.355469 17.152344 10.863281 17.152344 11.496094"
      fill="currentColor"
    />
  </svg>
</template>

<script setup>
  import { cn } from '@/lib/utils'

  const props = defineProps({
    class: {
      default: '',
      type: String,
    },
  })
</script>
