<script setup>
  import { AlertDialogDescription } from 'reka-ui'
  import { computed } from 'vue'

  import { cn } from '@/lib/utils'

  const props = defineProps({
    asChild: { type: Boolean, required: false },
    as: { type: null, required: false },
    class: { type: null, required: false },
  })

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })
</script>

<template>
  <AlertDialogDescription
    v-bind="delegatedProps"
    :class="cn('text-sm text-muted-foreground', props.class)"
  >
    <slot />
  </AlertDialogDescription>
</template>
