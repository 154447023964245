<script setup>
  import { cn } from '@/lib/utils'

  const props = defineProps({
    class: { type: null, required: false },
  })
</script>

<template>
  <main
    :class="
      cn(
        'relative flex min-h-svh flex-1 flex-col bg-background',
        'peer-data-[variant=inset]:min-h-[calc(100svh-theme(spacing.4))] md:peer-data-[variant=inset]:m-2 md:peer-data-[state=collapsed]:peer-data-[variant=inset]:ml-2 md:peer-data-[variant=inset]:ml-0 md:peer-data-[variant=inset]:rounded-xl md:peer-data-[variant=inset]:shadow',
        props.class,
      )
    "
  >
    <slot />
  </main>
</template>
