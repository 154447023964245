import { createGlobalState } from '@vueuse/core'
import { reactive } from 'vue'

export const useConfirmDialog = createGlobalState(() => {
  const dialog = reactive({
    confirmText: '',
    confirmVariant: '',
    description: '',
    open: false,
    title: '',
    callback: () => {},
  })

  const confirm = (options, callback) => {
    dialog.confirmText = options.confirmText ?? 'Confirm'
    dialog.confirmVariant = options.confirmVariant ?? 'destructive'
    dialog.description = options.description ?? ''
    dialog.open = true
    dialog.title = options.title ?? 'Are you sure?'

    dialog.callback = callback
  }

  return {
    dialog,
    confirm,
  }
})
