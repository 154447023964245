<template>
  <SidebarGroup>
    <SidebarMenu>
      <SidebarMenuItem v-for="link in adminLinks" :key="link.name">
        <SidebarMenuButton :tooltip="link.name" as-child @click="setOpenMobile(false)">
          <AppSidebarLink :link="link" />
        </SidebarMenuButton>
      </SidebarMenuItem>
    </SidebarMenu>
  </SidebarGroup>
  <SidebarGroup>
    <SidebarGroupLabel> Resources </SidebarGroupLabel>
    <SidebarMenu>
      <Collapsible
        v-for="group in adminGroups"
        :key="group.name"
        class="group/collapsible cursor-pointer"
        :default-open="group.links.some((link) => isActivePage(link.href))"
        as-child
      >
        <SidebarMenuItem>
          <CollapsibleTrigger as-child>
            <SidebarMenuButton :tooltip="group.name">
              <component :is="group.icon" v-if="group.icon" />
              <span class="font-medium"> {{ group.name }} </span>
              <ChevronRightIcon
                class="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90"
              />
            </SidebarMenuButton>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <SidebarMenuSub>
              <SidebarMenuSubItem v-for="link in group.links" :key="link.name">
                <SidebarMenuSubButton class="h-8" as-child @click="setOpenMobile(false)">
                  <AppSidebarLink :link="link" />
                </SidebarMenuSubButton>
              </SidebarMenuSubItem>
            </SidebarMenuSub>
          </CollapsibleContent>
        </SidebarMenuItem>
      </Collapsible>
    </SidebarMenu>
  </SidebarGroup>
</template>

<script setup>
  import {
    ArchiveIcon,
    ChevronRightIcon,
    HomeIcon,
    ImportIcon,
    LayersIcon,
    LayoutDashboardIcon,
  } from 'lucide-vue-next'

  import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/Components/ui/collapsible'
  import {
    SidebarGroup,
    SidebarGroupLabel,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    SidebarMenuSub,
    SidebarMenuSubButton,
    SidebarMenuSubItem,
    useSidebar,
  } from '@/Components/ui/sidebar'
  import { useActivePage } from '@/hooks/useActivePage'

  import AppSidebarLink from './AppSidebarLink.vue'

  const { isActivePage } = useActivePage()
  const { setOpenMobile } = useSidebar()

  const adminLinks = [
    {
      href: route('dashboard'),
      icon: LayoutDashboardIcon,
      name: 'Dashboard',
    },
    {
      href: route('listings.index'),
      icon: HomeIcon,
      name: 'Listings',
    },
  ]

  const adminGroups = [
    {
      name: 'Core',
      icon: LayersIcon,
      links: [
        {
          href: route('admin.accounts.index'),
          name: 'Accounts',
        },
        {
          href: route('admin.teams.index'),
          name: 'Teams',
        },
        {
          href: route('admin.users.index'),
          name: 'Users',
        },
      ],
    },
    {
      name: 'Records Management',
      icon: ArchiveIcon,
      links: [
        {
          href: route('admin.member-records.index'),
          name: 'Member Records',
        },
        {
          href: route('admin.office-records.index'),
          name: 'Office Records',
        },
      ],
    },
    {
      name: 'Imports',
      icon: ImportIcon,
      links: [
        {
          href: route('admin.listing-imports.index'),
          name: 'Listing Imports',
        },
        {
          href: route('admin.record-imports.index'),
          name: 'Record Imports',
        },
      ],
    },
  ]
</script>
