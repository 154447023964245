<template>
  <Link
    :class="[
      'transition-all',
      { 'bg-sidebar-accent text-sidebar-accent-foreground': isActivePage(link.href) },
    ]"
    :href="link.href"
  >
    <component :is="link.icon" v-if="link.icon" />
    <span class="font-medium">
      {{ link.name }}
    </span>
  </Link>
</template>

<script setup>
  import { useActivePage } from '@/hooks/useActivePage'

  defineProps({
    link: {
      default: () => ({
        href: '#',
        icon: undefined,
        name: '',
      }),
      type: Object,
    },
  })

  const { isActivePage } = useActivePage()
</script>
