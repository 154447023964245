import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

export function useAuth() {
  const page = usePage()

  const canCreateListingOnBehalfOfUser = computed(() => {
    if (isSuper.value) {
      return true
    }

    return page.props.features.post_on_behalf_of_agent
      ? page.props.auth.permissions.create_listings_on_behalf
      : false
  })

  const isAccountAdmin = computed(
    () => page.props.auth.role.key === page.props.enums.Role.BrokerAdmin,
  )

  const isOfficeAdmin = computed(
    () => page.props.auth.role.key === page.props.enums.Role.isOfficeAdmin,
  )

  const isImpersonating = computed(() => page.props.impersonating?.user)
  const isSuper = computed(() => page.props.auth.role.key === page.props.enums.Role.SuperAdmin)
  const isTeamUser = computed(() => page.props.auth.role.key === page.props.enums.Role.TeamUser)

  return {
    canCreateListingOnBehalfOfUser,
    isAccountAdmin,
    isImpersonating,
    isOfficeAdmin,
    isSuper,
    isTeamUser,
  }
}
