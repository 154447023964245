<script setup>
  import { ToastDescription } from 'reka-ui'
  import { computed } from 'vue'

  import { cn } from '@/lib/utils'

  const props = defineProps({
    asChild: { type: Boolean, required: false },
    as: { type: null, required: false },
    class: { type: null, required: false },
  })

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })
</script>

<template>
  <ToastDescription :class="cn('text-sm opacity-90', props.class)" v-bind="delegatedProps">
    <slot />
  </ToastDescription>
</template>
