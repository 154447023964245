<template>
  <SidebarFooter>
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu v-model:open="open">
          <DropdownMenuTrigger as-child>
            <SidebarMenuButton
              :class="['h-[52px] cursor-pointer', { 'bg-sidebar-accent': open }]"
              size="lg"
            >
              <Entity
                :avatar="$page.props.auth.user.profile_photo"
                class="data-[state=open]:w-min data-[state=open]:overflow-hidden data-[state=open]:pr-4"
                :fallback="$page.props.auth.user.initials"
                size="xs"
                :subtext="$page.props.auth.role.label"
                :text="$page.props.auth.user.fullname"
                text-class="font-medium"
              />
              <ChevronUpIcon
                :class="['ml-auto size-4 transition-transform', { 'rotate-180': !open }]"
                aria-hidden="true"
              />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            class="w-[--reka-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            side="bottom"
            align="end"
            :side-offset="8"
          >
            <DropdownMenuGroup>
              <!-- <DropdownMenuItem as-child>
                <a class="flex w-full items-center text-left" as="button" @click="toggleDark()">
                  <component
                    :is="isDark ? SunIcon : MoonIcon"
                    class="mr-2 size-4"
                    aria-hidden="true"
                  />
                  <span>{{ isDark ? 'Light' : 'Dark' }} Mode</span>
                </a>
              </DropdownMenuItem> -->

              <template v-if="!isSuper">
                <DropdownMenuItem as-child>
                  <a
                    class="flex w-full items-center text-left"
                    :href="route('settings.index')"
                    as="button"
                  >
                    <SettingsIcon class="mr-2 size-4" aria-hidden="true" />
                    <span>Settings</span>
                  </a>
                </DropdownMenuItem>
              </template>

              <DropdownMenuItem as-child>
                <Link
                  class="flex w-full items-center text-left text-destructive focus:bg-destructive/10 focus:text-destructive dark:focus:bg-destructive/30"
                  :href="route('logout')"
                  method="POST"
                  as="button"
                  type="button"
                >
                  <LogOutIcon class="mr-2 size-4" aria-hidden="true" />
                  Sign Out
                </Link>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  </SidebarFooter>
</template>

<script setup>
  import { ChevronUpIcon, LogOutIcon, SettingsIcon } from 'lucide-vue-next'
  import { ref } from 'vue'

  import Entity from '@/Components/oc/Entity.vue'
  import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
  } from '@/Components/ui/dropdown-menu'
  import {
    SidebarFooter,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
  } from '@/Components/ui/sidebar'
  import { useAuth } from '@/hooks/useAuth'
  // import useSidebarLayout from '@/hooks/useSidebarLayout'

  const open = ref(false)

  const { isSuper } = useAuth()
  // const { isDark, toggleDark } = useSidebarLayout()
</script>
