<template>
  <SidebarContent>
    <AppSidebarNavSuper v-if="isSuper" />
    <AppSidebarNavUser v-else />
  </SidebarContent>
</template>

<script setup>
  import { SidebarContent } from '@/Components/ui/sidebar'
  import { useAuth } from '@/hooks/useAuth'

  import { AppSidebarNavSuper, AppSidebarNavUser } from '.'

  const { isSuper } = useAuth()
</script>
