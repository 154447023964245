import '../css/app.css'
import './bootstrap'

import { createInertiaApp, Head, Link } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { createPinia } from 'pinia'
import { createApp, h } from 'vue'
import ConfettiExplosion from 'vue-confetti-explosion'
import Vue3Lottie from 'vue3-lottie'

import SidebarLayout from '@/Layouts/SidebarLayout.vue'

import { ZiggyVue } from '../../vendor/tightenco/ziggy'
import { i18n } from './i18n'

const appName = import.meta.env.VITE_APP_NAME
const appUrl = import.meta.env.VITE_APP_URL
const googleApiKey = import.meta.env.VITE_APP_GOOGLE_API_KEY

const Pinia = createPinia()

createInertiaApp({
  progress: false,
  resolve: (name) => {
    const page = resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'))

    page.then((module) => {
      module.default.layout = module.default.layout || SidebarLayout
    })

    return page
  },
  setup({ el, App, props, plugin }) {
    return createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(i18n)
      .use(ConfettiExplosion)
      .use(Pinia)
      .use(Vue3Lottie)
      .use(ZiggyVue)
      .provide('appName', appName)
      .provide('appUrl', appUrl)
      .provide('googleApiKey', googleApiKey)
      .component('Head', Head)
      .component('Link', Link)
      .mount(el)
  },
  title: (title) => (title ? `Ocusell | ${title}` : 'Ocusell'),
})
